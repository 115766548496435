import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import type { SelectChangeEvent } from "@mui/material/Select";
import Select from "@mui/material/Select";
import LayoutImage from "@/components/atoms/LayoutImage/LayoutImage.component";
import type { BuildingBlockType } from "@/components/organisms/BuildingBlockMapper";
import css from "./LayoutSelector.module.scss";
import { LayoutTypes } from "./LayoutTypes";

export type LayoutSelectorProps = {
    value?: LayoutTypes;
    onChange?: (value: LayoutTypes) => void;
    layoutOptions: {
        type: LayoutTypes;
    }[];
    type: BuildingBlockType;
};

export default function LayoutSelector({
    layoutOptions,
    value,
    onChange,
    type,
}: LayoutSelectorProps) {
    const { t } = useTranslation("common");
    const handleChange = (event: SelectChangeEvent<string>) => {
        if (onChange) onChange(event.target.value as LayoutTypes);
    };

    const inputLabel = t("select-layout", "Lay-out");

    const optionLabel = (type: string) => {
        switch (type) {
            case LayoutTypes.ImageLeft:
                return t(`layout-type.${type}`, "Media links");
            case LayoutTypes.ImageRight:
                return t(`layout-type.${type}`, "Media rechts");
            case LayoutTypes.TextCentered:
            case LayoutTypes.Centered:
                return t(`layout-type.${type}`, "Tekst gecentreerd");
            case LayoutTypes.TextCenteredThreeColumnGrid:
                return t(
                    `layout-type.${type}`,
                    "Tekst gecentreerd, drie kolommen",
                );
            case LayoutTypes.TextCenteredTwoColumnGrid:
                return t(
                    `layout-type.${type}`,
                    "Tekst gecentreerd, twee kolommen",
                );
            case LayoutTypes.TwoColumns:
                return t(`layout-type.${type}`, "Twee kolommen");
            case LayoutTypes.TextLeft:
                return t(`layout-type.${type}`, "Tekst links");
            case LayoutTypes.SmallOverlay:
                return t(`layout-type.${type}`, "Kleine overlay");
            case LayoutTypes.FullOverlay:
                return t(`layout-type.${type}`, "Volledige overlay");
            default:
                return type;
        }
    };

    return (
        <FormControl sx={{ minWidth: 180 }}>
            <InputLabel>{inputLabel}</InputLabel>
            <Select
                value={value || LayoutTypes.TwoColumns}
                label={inputLabel}
                onChange={handleChange}
            >
                {layoutOptions.map((option) => (
                    <MenuItem key={option.type} value={option.type}>
                        <div className={css.SelectItem}>
                            <LayoutImage layout={option.type} type={type} />{" "}
                            {optionLabel(option.type)}
                        </div>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
