import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import type { MpcAnswerType } from "@/components/organisms/BuildingBlockMapper";
import Input from "@/components/atoms/Input";
import Media from "@/components/atoms//Media";
import FauxRadio from "@/components/atoms/FauxRadio";
import css from "./MpcTextAnswer.module.scss";
import { useTranslation } from "react-i18next";
import type { ValidationError } from "yup";
import ValidationErrorLabel from "@/components/atoms/ValidationErrorLabel";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";
import clsx from "clsx";

export interface MpcTextAnswerProps {
    onRadioChange: (checked: boolean) => void;
    onMediaChange: (image: string | null) => void;
    onInputChange: (value: string) => void;
    onAddQuestion: () => void;
    onRemoveQuestion?: () => void;
    answer: MpcAnswerType;
    canHaveImages?: boolean;
    errors?: ValidationError[];
    showAddButton: boolean;
}

export function MpcTextAnswer({
    answer,
    canHaveImages,
    onMediaChange,
    onInputChange,
    onRadioChange,
    onRemoveQuestion,
    onAddQuestion,
    errors,
    showAddButton,
}: MpcTextAnswerProps) {
    const { t } = useTranslation("common", { keyPrefix: "mpc-answers" });

    const labelError = resolveError({
        errors,
        resolver: (error) => error?.path?.endsWith("label") ?? false,
    });

    const imageError = canHaveImages
        ? resolveError({
              errors,
              resolver: (error) => error?.path?.endsWith("image.id") ?? false,
          })
        : undefined;

    return (
        <Stack
            key={answer.id}
            direction="row"
            alignItems={"center"}
            gap={1}
            component={Paper}
            sx={{ p: 2 }}
        >
            <FauxRadio checked={answer.isCorrect} onChange={onRadioChange} />
            {canHaveImages && (
                <div className={clsx(css.thumb, { [css.Error]: imageError })}>
                    <Media
                        image={answer.image}
                        label=" "
                        onChange={onMediaChange}
                    />
                </div>
            )}
            <Stack flex="1" gap={1}>
                {labelError && (
                    <ValidationErrorLabel
                        keyPrefix={"mpc-answers.label"}
                        error={labelError}
                    />
                )}
                <Input
                    value={answer.label}
                    onChange={onInputChange}
                    placeholder={t("answer")}
                />
            </Stack>
            <Stack direction="row" mr="0" ml="auto">
                {onRemoveQuestion && (
                    <IconButton
                        onClick={onRemoveQuestion}
                        title={t("delete-answer")}
                    >
                        <Delete />
                    </IconButton>
                )}
                {showAddButton && (
                    <IconButton onClick={onAddQuestion} title={t("add-answer")}>
                        <Add />
                    </IconButton>
                )}
            </Stack>
        </Stack>
    );
}

export default MpcTextAnswer;
