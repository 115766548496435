import { z } from "zod";
import { baseBlockSchema } from "./base-block.schema";
import { ObjectFit, ContentBlockLayout } from "@bespeak/apollo";

export const cmsContentBlockLayoutSchema = z.enum([
    ContentBlockLayout.ImageLeft,
    ContentBlockLayout.ImageRight,
    ContentBlockLayout.TextCentered,
    ContentBlockLayout.TwoColumns,
]);

export const cmsObjectFitSchema = z.enum([ObjectFit.Boxed, ObjectFit.Full]);

export const cmsContentBlockSchema = baseBlockSchema.merge(
    z.object({
        id: z.union([z.string().uuid(), z.number()]).optional().nullable(),
        type: z.literal("CONTENT"),
        normalizedType: z.literal("CONTENT").catch("CONTENT"),
        assetId: z.string().uuid().nullable().optional(),
        contentColumnOne: z.string().default(""),
        contentColumnTwo: z.string().default(""),
        objectFit: cmsObjectFitSchema.default(ObjectFit.Boxed),
        textLayout: cmsContentBlockLayoutSchema.optional(),
        layout: cmsContentBlockLayoutSchema.optional(),
    }),
);
