import type { appContentBlockSchema } from "@bespeak/schema/schemas/app-content-block-schema";
import type { appListBlockSchema } from "@bespeak/schema/schemas/app-list-block.schema";
import { lazy } from "react";
import type z from "zod";

export type AppBlock =
    | z.infer<typeof appContentBlockSchema>
    | z.infer<typeof appListBlockSchema>;

function decorator<
    C extends React.ComponentType<any>,
    PM = (block: AppBlock) => boolean,
>(Component: C, propsMatcher?: PM) {
    return { Component, matchesProps: propsMatcher };
}

export const List = decorator(
    lazy(
        () =>
            import(
                "@base/molecules/BuildingBlocks/Blocks/ListBlock/list-block"
            ),
    ),
    (block) =>
        block.type === "CONTENT" &&
        "list" in block &&
        "items" in block.list &&
        Array.isArray(block.list.items),
);

export const Content = decorator(
    lazy(
        () =>
            import(
                "@base/molecules/BuildingBlocks/Blocks/ContentBlock/content-block"
            ),
    ),
    (block) => block.type === "CONTENT",
);

export function RenderComponent(block: AppBlock) {
    switch (true) {
        case List.matchesProps?.(block):
            return List.Component;
        case Content.matchesProps?.(block):
            return Content.Component;
        default:
            return null;
    }
}
