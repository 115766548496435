import { type ComponentProps } from "react";
import { Dialog, Button } from "@bespeak/ui";
import { useTranslation } from "react-i18next";
import { Typography } from "@/components/base/atoms";

export namespace LicenseExpiredDialog {
    export type Props = {
        componentProps?: {
            Dialog?: Omit<ComponentProps<typeof Dialog>, "children">;
        };
        onConfirm?: () => void;
        products: string[];
    };
}

export function LicenseExpiredDialog({
    componentProps,
    onConfirm,
    products = [],
}: LicenseExpiredDialog.Props) {
    const { t } = useTranslation("translation", {
        keyPrefix: "organisms.license-expired-dialog",
    });

    return (
        <Dialog active {...componentProps?.Dialog} backdrop>
            <Dialog.Body maxWidth="fit-content">
                <Dialog.Content>
                    <div className="flex flex-col gap-4">
                        <Typography variant="p">
                            {t("content.body1")}
                        </Typography>
                        <ul className="flex flex-col gap-0">
                            {products.map((product) => (
                                <Typography
                                    key={product}
                                    variant="li"
                                    weight="bold"
                                >
                                    {product}
                                </Typography>
                            ))}
                        </ul>
                        <Typography variant="p">
                            {t("content.body2", { count: products.length })}
                        </Typography>
                    </div>
                </Dialog.Content>
                <Dialog.Footer center>
                    <Button
                        type={"button"}
                        variant={"text"}
                        onClick={onConfirm}
                    >
                        {t("footer.button")}
                    </Button>
                </Dialog.Footer>
            </Dialog.Body>
        </Dialog>
    );
}

export default LicenseExpiredDialog;
