import SearchResult from "@/components/atoms/SearchResult/SearchResult.component";
import css from "./SearchResults.module.css";
import { z } from "zod";
import { useMemo } from "react";

export type SearchResult = {
    type: string;
    name: string;
    module_name?: string;
    educational_path_name?: string;
    description?: string;
    linkToId?: string;
    assetId?: string;
};

export type SearchResultsProps = {
    results?: SearchResult[];
    searchString: string;
};

const resultsSchema = z.array(
    z
        .object({
            objectID: z.string(),
            didactic_tool_id: z.string().optional(),
            module_id: z.string().optional(),
            image: z
                .string()
                .optional()
                .transform((value) => {
                    if (value === "null") {
                        //Algolia returns "null" as a string
                        return null;
                    }
                    return value;
                }),
            educational_path_id: z.string().optional(),
            name: z.string(), //DM Name
            module_name: z.string().optional(),
            educational_path_name: z.string().optional(),
            description: z.string().optional(),
            _tags: z.array(z.string()).optional(),
            didactic_tool_type: z
                .string()
                .optional()
                .transform((value) => {
                    if (!value) {
                        return "module";
                    }
                    return value;
                }),
            reference_card_id: z.string().optional(),
            educational_program_id: z.string().optional(),
        })
        .transform((value) => ({
            ...value,
            type: (() => {
                if (value._tags?.includes("reference_card")) {
                    return "reference-card";
                }
                return value.didactic_tool_type;
            })(),
        })),
);

export const SearchResults = ({
    results,
    searchString,
}: SearchResultsProps) => {
    const searchResults = useMemo(() => {
        return resultsSchema.parse(results) || [];
    }, [results]);

    return (
        <div className={css.SearchResults}>
            {searchResults.map(
                ({
                    name,
                    module_name,
                    educational_path_name,
                    description,
                    didactic_tool_id,
                    module_id,
                    educational_path_id,
                    type,
                    image,
                    objectID,
                    educational_program_id,
                    reference_card_id,
                }) => (
                    <SearchResult
                        key={objectID}
                        linkToIds={{
                            didactic_tool_id: didactic_tool_id,
                            module_id: module_id,
                            educational_path_id: educational_path_id,
                            reference_card_id: reference_card_id,
                            educational_program_id: educational_program_id,
                        }}
                        type={type}
                    >
                        <SearchResult.Asset
                            type={type || "LESSON"}
                            assetId={image || ""}
                        />
                        <SearchResult.Content
                            type={type || "LESSON"}
                            title={
                                name ||
                                module_name ||
                                educational_path_name ||
                                ""
                            }
                            description={description}
                            searchString={searchString}
                        />
                    </SearchResult>
                ),
            )}
        </div>
    );
};

export default SearchResults;
