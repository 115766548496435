import { useEffect, useRef, useState } from "react";
import type { Key } from "@react-types/shared";
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useKey, useOutsideClickRef } from "rooks";
import { useGetEducationalProgramsForApp } from "@/services";
import { useProgramStore } from "@/store/preferences";
import Button from "@/components/base/atoms/Button";
import { CheckIcon, ChevronDownIcon } from "@/components/base/icons";
import type { UserDto } from "@bespeak/apollo";

export type MobileProgramDropdownProps = {
    user: Partial<UserDto>;
    onChangeProgram: () => void;
};

const MobileProgramDropdown = ({
    user,
    onChangeProgram,
}: MobileProgramDropdownProps) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const { educationalPrograms } = useGetEducationalProgramsForApp();

    const navigate = useNavigate();
    const location = useLocation();
    const programStore = useProgramStore();
    const storedProgramId = (programStore.value || "").toString();

    const handleSelectionChange = (programId: Key) => {
        if (!user) return;

        setDropdownOpen(false);
        onChangeProgram();

        programStore.setValue(programId.toString());

        if (location.pathname !== "/rapportage") {
            navigate(`/program/${programId}`);
        }
    };

    const handleCloseDropdown = () => {
        setDropdownOpen(false);
    };

    const [programDropdownRef] = useOutsideClickRef(handleCloseDropdown);

    useKey("Escape", () => {
        handleCloseDropdown();
    });

    useEffect(() => {
        if (dropdownRef.current) {
            const rect = dropdownRef.current.getBoundingClientRect();
            dropdownRef.current.style.maxHeight = `calc(100vh - ${rect.top}px)`;
        }
    }, [dropdownOpen]);

    return (
        <div ref={programDropdownRef} className="flex flex-col gap-1">
            <Button
                variant="neutralGhostDark"
                size="small"
                className="w-full justify-between"
                onClick={() => setDropdownOpen(!dropdownOpen)}
                isActive={dropdownOpen}
            >
                {educationalPrograms?.find(
                    (program) => program.id === storedProgramId,
                )?.name || educationalPrograms?.[0]?.name}
                <ChevronDownIcon
                    className={clsx(
                        "h-5 w-5 transition-transform duration-300 ease-in-out will-change-transform",
                        {
                            "-scale-y-100": dropdownOpen,
                        },
                    )}
                />
            </Button>

            <div
                className={clsx(
                    "grid transition-[grid-template-rows] duration-300",
                    {
                        "grid-rows-[1fr]": dropdownOpen,
                        "grid-rows-[0fr]": !dropdownOpen,
                    },
                )}
            >
                <div className="overflow-hidden">
                    <div className="flex flex-col gap-1">
                        {educationalPrograms?.map((program) => (
                            <Button
                                key={program.id}
                                variant="neutralGhostDark"
                                size="small"
                                onClick={() =>
                                    handleSelectionChange(program.id)
                                }
                                className="w-full justify-between"
                            >
                                <div className="mr-auto">{program.name}</div>
                                {program.id === storedProgramId && (
                                    <CheckIcon className="h-5 w-5" />
                                )}
                            </Button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileProgramDropdown;
