import { useEffect } from "react";
import {
    createBrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { routes } from "@/provider/Router/routes";
import { labelConfig } from "@/whitelabels";

const initSentry = async () => {
    const href = import.meta.env.VITE_APP_WHITELABEL_OVERRIDE || location.href;
    const whiteLabelConfig = await labelConfig(href);

    if (whiteLabelConfig?.sentryEnv) {
        Sentry.init({
            dsn: whiteLabelConfig.sentryEnv,
            integrations: [
                Sentry.reactRouterV6BrowserTracingIntegration({
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                }),
            ],
            tracesSampleRate: 1.0,
        });

        const sentryCreateBrowserRouter =
            Sentry.wrapCreateBrowserRouter(createBrowserRouter);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const router = sentryCreateBrowserRouter(routes);
    }
};

initSentry();
