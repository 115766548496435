import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Scope, usePermissionsStore } from "@/store/permissions";
import Button from "@/components/base/atoms/Button";
import { ArrowLeftIcon, HomeLineIcon } from "@/components/base/icons";
import { useTranslation } from "react-i18next";
import useHistoryPath from "@/lib/useHistoryPath/useHistoryPath";
import NavigationBreadcrumbs from "../navigation-breadcrumbs/navigation-breadcrumbs";
import { useGetEducationalPathForApp, useGetModuleForApp } from "@/services";

const NavigationBarLeft = () => {
    const { t } = useTranslation("common", { keyPrefix: "main-menu" });
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { hasPermission } = usePermissionsStore();
    const hasReferenceCardPermissions = hasPermission(
        Scope.REFERENCE_CARDS_READ,
    );

    const { programId, learningPathId, moduleId, didacticToolId } = useParams<{
        programId: string;
        learningPathId: string;
        moduleId: string;
        didacticToolId: string;
    }>();

    const { educationalPath } = useGetEducationalPathForApp(
        learningPathId || "",
    );
    const { module } = useGetModuleForApp(moduleId || "");

    const menuLinks = hasReferenceCardPermissions
        ? [
              {
                  label: t("reference"),
                  href: "/naslag",
              },
          ]
        : [];

    const historyPath = useHistoryPath(pathname);

    const handleHistoryBack = () => {
        window.scrollTo(0, 0);
        navigate(historyPath);
    };

    if (learningPathId) {
        return (
            <div className="mr-16 flex items-center gap-6 overflow-hidden">
                <Button
                    onClick={() => handleHistoryBack()}
                    size="small"
                    variant="primaryGhost"
                >
                    <ArrowLeftIcon className="h-5 w-5" />
                    {t("back")}
                </Button>
                {moduleId && (
                    <>
                        <div className="border-outline-300 h-11 border-r"></div>
                        <NavigationBreadcrumbs
                            programId={programId}
                            moduleId={moduleId}
                            didacticToolId={didacticToolId}
                            learningPath={educationalPath}
                            module={module}
                        />
                    </>
                )}
            </div>
        );
    }

    return (
        <nav>
            <ul className="flex items-center gap-1.5">
                <li>
                    <Button
                        variant="primaryGhost"
                        size="small"
                        iconOnly
                        isActive={
                            window.location.pathname ===
                            (programId ? `/program/${programId}` : "/")
                        }
                        onClick={() => {
                            navigate(programId ? `/program/${programId}` : "/");
                        }}
                    >
                        <HomeLineIcon className="h-5 w-5" />
                    </Button>
                </li>
                {menuLinks.map((link, index) => (
                    <li key={`menu-link-${index}`}>
                        <Button
                            variant="primaryGhost"
                            size="small"
                            isActive={link.href === window.location.pathname}
                            onClick={() => {
                                navigate(link.href);
                            }}
                        >
                            {link.label}
                        </Button>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default NavigationBarLeft;
