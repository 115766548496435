/* eslint-disable sonarjs/no-duplicate-string */
import classNames from "@/lib/class-names/class-names";
import type { ReactNode } from "react";

export type ButtonProps = {
    children?: ReactNode;
    size?: "small" | "medium" | "large";
    variant:
        | "primary"
        | "neutral"
        | "ghost"
        | "primaryGhost"
        | "neutralGhost"
        | "neutralGhostDark"
        | "outlined"
        | "neutralLink"
        | "primaryLink"
        | "neutralOutlined"
        | "none"
        | "icon";
    className?: string;
    outlined?: boolean;
    disabled?: boolean;
    iconOnly?: boolean;
    isActive?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({
    children,
    onClick,
    variant,
    className = "",
    size,
    iconOnly = false,
    isActive = false,
    ...props
}: ButtonProps) => {
    const button = {
        primary: `bg-primary hover:bg-primary-dim fill-white text-white disabled:bg-neutral-200 stroke-white ${isActive ? "bg-primary-dim" : ""}`,
        neutral: `bg-neutral-800 hover:bg-neutral-900 text-white stroke-white disabled:bg-neutral-200 ${isActive ? "bg-neutral-900" : ""}`,
        ghost: "text-neutral-700 stroke-neutral-700 fill-neutral-700 font-bold bg-transparant",
        primaryGhost: `text-neutral-800 stroke-primary fill-primary font-bold hover:bg-secondary-dim ${isActive ? "bg-secondary-dim" : ""}`,
        neutralGhost: `text-neutral-800 stroke-neutral-800 fill-neutral-800 font-bold hover:bg-neutral-200 ${isActive ? "bg-neutral-200" : ""}`,
        neutralGhostDark: `text-white stroke-white fill-white font-bold hover:bg-neutral-200 bg-opacity-20 ${isActive ? "bg-neutral-200" : ""}`,
        outlined:
            "border-2 text-neutral-700 stroke-neutral-700 fill-neutral-700 font-bold bg-transparant",
        neutralLink: `background-transparant border-none text-neutral-700 stroke-neutral-700 hover:text-neutral-900 ${isActive ? "text-neutral-900" : ""}`,
        primaryLink: `background-transparant border-none text-primary stroke-primary hover:text-primary-dim hover:stroke-primary-dim hover:fill-primary-dim ${isActive ? "text-primary-dim stroke-primary-dim fill-primary-dim" : ""}`,
        neutralOutlined: `border-2 font-bold bg-white hover:bg-neutral-200 ${isActive ? "bg-neutral-200" : ""}`,
        icon: "",
        none: "",
    };

    const height = {
        small: "h-11",
        medium: "h-12",
        large: "h-14",
    };

    const width = iconOnly
        ? {
              small: "w-11",
              medium: "w-12",
              large: "w-14",
          }
        : {};

    const padding =
        variant !== "neutralLink" &&
        variant !== "primaryLink" &&
        variant !== "none" &&
        !iconOnly
            ? "px-4 py-3"
            : "";

    return (
        <button
            className={classNames(
                "flex items-center justify-center gap-2 rounded-button font-bold transition-colors duration-200 disabled:text-neutral-500",
                padding,
                size ? height[size] : "",
                size && iconOnly ? width[size] : "",
                button[variant],
                className,
            )}
            onClick={onClick}
            {...props}
        >
            {children}
        </button>
    );
};

export default Button;
