import { AlgoliaSearchProvider } from "@/provider/AlgoliaSearch";
import { SearchLayout } from "./SearchLayout.component";
import { useContext } from "react";
import { ThemeContext } from "@bespeak/ui/src/Theme";
import NavigationBar from "@/components/base/organisms/navigation-bar/navigation-bar";
import MobileNav from "@/components/base/organisms/mobile-nav/mobile-nav";
import { useMediaQuery } from "usehooks-ts";

export const Search = () => {
    const theme = useContext(ThemeContext);
    const mediaQueryDesktop = useMediaQuery("(min-width: 1024px)");

    return (
        <AlgoliaSearchProvider applicationID={theme?.algoliaApplicationID}>
            {mediaQueryDesktop ? <NavigationBar /> : <MobileNav />}
            <SearchLayout />
        </AlgoliaSearchProvider>
    );
};

export default Search;
