import { retryDecorator } from "ts-retry-promise";
import { getIsAuthenticated } from "./getIsAuthenticated";

export const getIsAuthenticatedRetryable = retryDecorator(
    async () => getIsAuthenticated(),
    {
        until: (res) => res === true,
        backoff: "FIXED",
        retries: 100,
    },
);
