import {
    BuildingBlockBackground,
    ContentBlockLayout,
    ContentBlockListLayout,
    ConversationBlockLayout,
    HotspotBlockLayout,
    MoodboardBlockLayout,
    PollVoteOption,
    QuestionConnectBlockLayout,
    QuoteBlockLayout,
    StandBlockLayout,
} from "@bespeak/apollo";

import { BuildingBlockType } from "./BuildingBlocks.types";

import type {
    BuildingBlock,
    ContentBuildingBlock,
    MpcQuestionBuildingBlock,
    StandQuestionBuildingBlock,
    MoodboardBuildingBlock,
    QuestionConnectBuildingBlock,
    ConversationBuildingBlock,
    SortQuestionBuildingBlock,
    CarouselBuildingBlock,
    QuoteBuildingBlock,
    ReferenceCardBuildingBlock,
    HotspotBuildingBlock,
    SceneBuildingBlock,
    LinkBuildingBlock,
    PollBuildingBlock,
    InteractiveQuestionBuildingBlock,
} from "./BuildingBlocks.types";

export function createNewBlock(
    type: BuildingBlockType = BuildingBlockType.CONTENT,
): BuildingBlock {
    switch (type) {
        case BuildingBlockType.CONTENT:
            return {
                id: Date.now(),
                type,
                contentColumnOne: "",
                layout: ContentBlockLayout.TwoColumns,
                list: undefined,
            } satisfies ContentBuildingBlock;

        case BuildingBlockType.CONTENT_LIST:
            return {
                id: Date.now(),
                type,
                contentColumnOne: "",
                layout: ContentBlockLayout.TwoColumns,
                list: {
                    layout: ContentBlockListLayout.TwoColumns,
                    items: [
                        {
                            id: Date.now(),
                            title: "",
                            subtitle: "",
                            content: "",
                            assetId: undefined,
                            sortOrder: 0,
                        },
                    ],
                },
            } satisfies ContentBuildingBlock;

        case BuildingBlockType.MPC:
        case BuildingBlockType.MPC_WITH_IMAGES:
            return {
                id: Date.now(),
                type,
                instructionDivider: true,
                answers: [{ id: Date.now(), label: "" }],
                helpText: "",
                label: {
                    id: Date.now(),
                    label: "",
                },
            } satisfies MpcQuestionBuildingBlock;

        case BuildingBlockType.STAND:
            return {
                id: Date.now(),
                type,
                layout: StandBlockLayout.TextCentered,
                label: {
                    id: Date.now(),
                    label: "",
                },
            } satisfies StandQuestionBuildingBlock;

        case BuildingBlockType.MOODBOARD:
            return {
                id: Date.now(),
                type,
                moodboardLayout: MoodboardBlockLayout.SmallOverlay,
                images: [{ assetId: undefined, sortOrder: 0 }],
            } satisfies MoodboardBuildingBlock;

        case BuildingBlockType.QUESTION_CONNECT:
            return {
                id: Date.now(),
                type,
                question: "",
                layout: QuestionConnectBlockLayout.Centered,
                answers: [{ id: Date.now(), label: "" }],
                label: {
                    id: Date.now(),
                    label: "",
                },
            } satisfies QuestionConnectBuildingBlock;

        case BuildingBlockType.CONVERSATION:
            return {
                id: Date.now(),
                type,
                persons: [{ id: Date.now(), name: "" }],
                messages: [{ id: Date.now(), message: "", personIndex: "0" }],
                layout: ConversationBlockLayout.Default,
                interval: 0,
                label: "",
                quote: "",
                quotePersonIndex: 0,
                background: "DEFAULT",
            } satisfies ConversationBuildingBlock;

        case BuildingBlockType.SORTQUESTION:
            return {
                id: Date.now(),
                type,
                question: "",
                answers: [
                    { id: Date.now() * 921, label: "", order: 0 },
                    { id: Date.now() * 923, label: "", order: 1 },
                    { id: Date.now() * 928, label: "", order: 2 },
                ],
                label: {
                    id: Date.now(),
                    label: "",
                },
            } satisfies SortQuestionBuildingBlock;

        case BuildingBlockType.CAROUSEL:
            return {
                id: Date.now(),
                type,
                title: "",
                blocks: [],
                background: BuildingBlockBackground.White,
            } satisfies CarouselBuildingBlock;

        case BuildingBlockType.QUOTE:
            return {
                id: Date.now(),
                type,
                name: "",
                quote: "",
                assetId: "",
                background: "DEFAULT",
                layout: QuoteBlockLayout.ImageLeft,
            } satisfies QuoteBuildingBlock;

        case BuildingBlockType.REFERENCE_CARDS_CAROUSEL:
            return {
                id: Date.now(),
                type,
                title: "",
                layout: "DEFAULT",
                cards: [],
                background: "DEFAULT",
            } satisfies ReferenceCardBuildingBlock;

        case BuildingBlockType.HOTSPOT:
            return {
                id: Date.now(),
                type,
                title: "",
                content: "",
                assetId: undefined,
                spots: [],
                background: "DEFAULT",
                layout: HotspotBlockLayout.ImageLeft,
            } satisfies HotspotBuildingBlock;

        case BuildingBlockType.SCENE:
            return {
                id: Date.now(),
                type,
                asset: undefined,
                title: "",
                description: "",
            } satisfies SceneBuildingBlock;

        case BuildingBlockType.LINK:
            return {
                id: Date.now(),
                type,
                title: "",
                instruction: "",
                url: "",
                linkLabel: "",
                openInNewWindow: false,
                asset: undefined,
            } satisfies LinkBuildingBlock;

        case BuildingBlockType.POLL:
            return {
                id: Date.now(),
                type,
                voteOption: PollVoteOption.Single,
            } satisfies PollBuildingBlock;

        case BuildingBlockType.INTERACTIVE_QUESTION:
            return {
                id: Date.now(),
                type,
                question: undefined,
                sceneWhenWrong: {
                    id: Date.now(),
                    type: "SCENE",
                    asset: undefined,
                    title: "",
                    description: "",
                },
            } satisfies InteractiveQuestionBuildingBlock;

        default:
            throw new Error(`Unknown building block type: ${type}`);
    }
}
