import { BuildingBlockBackground } from "@bespeak/apollo";

const DEFAULT = BuildingBlockBackground.Default;

export function getBackgroundColors(filter?: string[]) {
    const values = Object.values(BuildingBlockBackground);
    const keys = Object.keys(BuildingBlockBackground);

    const options = values
        .map((value, index) => ({
            value: value,
            label: keys[index].toLowerCase(),
        }))
        .sort((a, b) =>
            a.value === DEFAULT ? -1 : b.value === DEFAULT ? 1 : 0,
        );

    return filter
        ? options.filter((x) => filter.some((f) => f === x.value))
        : options;
}

export default getBackgroundColors;
