function getInitials(name: string) {
    const nameWords = name.split(" ");
    const firstWord = name[0];
    const lastWord = nameWords[nameWords.length - 1];
    return nameWords.length === 1
        ? name.substring(0, 2)
        : firstWord.charAt(0) + lastWord.charAt(0);
}

export default getInitials;
