import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TransitionGroup } from "react-transition-group";
import Stack from "@mui/material/Stack";
import Input from "@/components/atoms/Input";
import Block from "@/components/atoms/Block";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import FormLabel from "@mui/material/FormLabel";
import type { PollBuildingBlock } from "@/components/organisms/BuildingBlockMapper";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";
import AnswerControl from "./answer-control";
import ValidationErrorLabel from "@/components/atoms/ValidationErrorLabel";
import PollVoteOptionSelector from "@/components/molecules/PollVoteOptionSelector/PollVoteOptionsSelector.component";

export namespace PollBuildingBlockEditorComponent {
    export interface Props extends PollBuildingBlock {
        elevate?: boolean;
        onChange?: (buildingBlock: PollBuildingBlock) => void;
    }
}

export function PollBuildingBlockEditorComponent(
    props: PollBuildingBlockEditorComponent.Props,
) {
    const { t } = useTranslation("translation", {
        keyPrefix: "organisms.buildingBlockMapper.blocks.poll",
    });

    type StringTypeFields = Pick<
        PollBuildingBlock,
        "instruction" | "question" | "introduction"
    >;

    const handleStringTypeChange =
        (key: keyof StringTypeFields) =>
        (value: StringTypeFields[keyof StringTypeFields]) => {
            props.onChange?.({
                ...props,
                [key]: value,
            });
        };

    const registerControlForStringType = (key: keyof StringTypeFields) => ({
        value: props[key],
        onChange: handleStringTypeChange(key),
        label: t(`${key}.label`),
        placeholder: t(`${key}.placeholder`),
        variant: "body1" as const,
        error: resolveError({
            errors: props.errors,
            path: key,
        }),
    });

    const answersError = resolveError({
        errors: props.errors,
        path: "answers",
    });

    const answerControlsProps: (AnswerControl.Props & { id: string })[] =
        useMemo(() => {
            return [...(props.answers ?? [])] // Copy the array to avoid mutation
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((answer, index) => ({
                    id: answer.id,
                    value: answer.text,
                    canMoveUp: answer.sortOrder > 0,
                    canMoveDown:
                        answer.sortOrder < (props.answers?.length ?? 0) - 1,
                    errors: props.errors?.filter((error) =>
                        error?.path?.startsWith(`answers[${index}]`),
                    ),
                    /**
                     * Text Change Handler
                     *
                     * Handle text change by updating current item
                     * @param value
                     */
                    onChange: (value: string) => {
                        props.onChange?.({
                            ...props,
                            answers: props.answers.map((a) =>
                                a === answer ? { ...a, text: value } : a,
                            ),
                        });
                    },
                    /**
                     * Delete Handler
                     *
                     * Handle delete action by removeing it from array
                     */
                    onDelete: () => {
                        props.onChange?.({
                            ...props,
                            answers: props.answers.filter(
                                (a) => a.id !== answer.id,
                            ),
                        });
                    },
                    /**
                     * Move Up Handler
                     *
                     * Handle move order by changing sort order
                     * The sort order is used to sort the answers
                     */
                    onMoveUp: () => {
                        const index = props.answers?.findIndex(
                            (a) => a.id === answer.id,
                        );
                        if (index == null || index <= 0) {
                            return;
                        }
                        const previous = props.answers[index - 1];
                        props.onChange?.({
                            ...props,
                            answers: props.answers
                                .map((a) => {
                                    if (a === answer) {
                                        return {
                                            ...a,
                                            sortOrder: previous.sortOrder,
                                        };
                                    }
                                    if (a === previous) {
                                        return {
                                            ...a,
                                            sortOrder: answer.sortOrder,
                                        };
                                    }
                                    return a;
                                })
                                .sort((a, b) => a.sortOrder - b.sortOrder),
                        });
                    },
                    /**
                     * Move Down Handler
                     *
                     * Handle move order by changing sort order
                     * The sort order is used to sort the answers
                     */
                    onMoveDown: () => {
                        const index = props.answers?.findIndex(
                            (a) => a.id === answer.id,
                        );
                        if (
                            index == null ||
                            index >= (props.answers?.length ?? 0)
                        ) {
                            return;
                        }
                        const next = props.answers[index + 1];
                        props.onChange?.({
                            ...props,
                            answers: props.answers
                                .map((a) => {
                                    if (a === answer) {
                                        return {
                                            ...a,
                                            sortOrder: next.sortOrder,
                                        };
                                    }
                                    if (a === next) {
                                        return {
                                            ...a,
                                            sortOrder: answer.sortOrder,
                                        };
                                    }
                                    return a;
                                })
                                .sort((a, b) => a.sortOrder - b.sortOrder),
                        });
                    },
                }));
        }, [props]);

    const handleAddAnswerAtIndex = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            const index = Number(event.currentTarget.dataset.index);
            props.onChange?.({
                ...props,
                answers: [
                    ...(props.answers ?? []).slice(0, index),
                    {
                        id: generateTemporaryId(),
                        text: "",
                        sortOrder: index,
                    },
                    ...(props.answers ?? []).slice(index),
                ],
            });
        },
        [props],
    );

    return (
        <Block elevate={props.elevate}>
            <Block.Header>
                <PollVoteOptionSelector
                    value={props.voteOption}
                    onChange={(voteOption) =>
                        props.onChange?.({
                            ...props,
                            voteOption,
                        })
                    }
                />
            </Block.Header>
            <Block.Main>
                <Stack gap={1} direction="column">
                    <Input {...registerControlForStringType("instruction")} />
                    <Input {...registerControlForStringType("question")} />
                    <Input {...registerControlForStringType("introduction")} />
                    <Divider />
                    <ValidationErrorLabel
                        error={answersError}
                        keyPrefix="answers.errors.input"
                    />
                    <FormLabel error={!!answersError}>
                        {t("answers.label")}
                    </FormLabel>
                    <List dense>
                        <center>
                            <TransitionGroup>
                                {answerControlsProps?.map((answer) => (
                                    <Collapse key={answer.id}>
                                        <AnswerControl
                                            key={answer.id}
                                            {...answer}
                                        />
                                    </Collapse>
                                ))}
                            </TransitionGroup>
                            <Button
                                variant="text"
                                onClick={handleAddAnswerAtIndex}
                                data-index={answerControlsProps.length}
                            >
                                {t("add-answer")}
                            </Button>
                        </center>
                    </List>
                </Stack>
            </Block.Main>
        </Block>
    );
}

function generateTemporaryId(): string {
    // @ts-expect-error - we actually check if this is a number
    return +Date.now();
}

export default PollBuildingBlockEditorComponent;
