import Stack from "@mui/material/Stack";
import Block from "@/components/atoms/Block";
import { useTranslation } from "react-i18next";
import css from "./ConversationBuildingBlock.module.scss";
import { useState } from "react";
import clsx from "clsx";
import SelectBgColor from "./common/SelectBgColor/SelectBgColor.component";
import { Typography } from "@mui/material";
import ConversationPersons from "@/components/molecules/ConversationPersons/ConversationPersons";
import ConversationMessages from "@/components/molecules/ConversationMessages/ConversationMessages";
import { type ConversationBuildingBlock } from "@/components/organisms/BuildingBlockMapper";

export interface ConversationBuildingBlockComponentProps
    extends ConversationBuildingBlock {
    onChange?: (buildingBlock: ConversationBuildingBlock) => void;
}

export function ConversationBuildingBlockComponent(
    props: ConversationBuildingBlockComponentProps,
) {
    const { t } = useTranslation("common");
    const [bgColor, setBgColor] = useState(props.background || "DEFAULT");

    type Fields = Pick<
        ConversationBuildingBlock,
        "quote" | "background" | "label"
    >;

    const handleChange =
        (key: keyof Fields) => (value: Fields[keyof Fields]) => {
            props.onChange?.({
                ...props,
                [key]: value,
            });
        };

    const registerControl = (key: keyof Fields) => ({
        value: props[key],
        onChange: handleChange(key),
    });

    const persons = (
        <ConversationPersons
            errors={props.errors}
            persons={props.persons}
            onChange={(persons) =>
                props.onChange?.({
                    ...props,
                    persons: persons,
                })
            }
        />
    );

    const messages = (
        <ConversationMessages
            errors={props.errors}
            messages={props.messages}
            persons={props.persons}
            onChange={(messages) =>
                props.onChange?.({
                    ...props,
                    messages: messages,
                })
            }
        />
    );

    return (
        <Block>
            <Block.Header>
                <Stack gap={1} direction="row" alignItems={"center"}>
                    <SelectBgColor
                        {...registerControl("background")}
                        filter={["NEUTRAL_LIGH", "DEFAULT"]}
                        onChange={(event) => {
                            setBgColor(event);
                            props.onChange?.({
                                ...props,
                                background: event,
                            });
                        }}
                    />
                </Stack>
            </Block.Header>
            <Block.Main
                className={clsx({ [css.primary]: bgColor === "PRIMARY" })}
            >
                <Stack gap={4} direction="row">
                    <Stack gap={4} flexBasis="100%">
                        {/* {quote} */}
                        {/* {name} */}
                        <Stack gap={4} flexBasis="100%">
                            <Typography variant="h5">
                                {t("persons-title", "Personen")}
                            </Typography>
                            {persons}
                        </Stack>
                        <Stack gap={4} flexBasis="100%">
                            <Typography variant="h5">
                                {t("messages-title", "Berichten")}
                            </Typography>
                            {messages}
                        </Stack>
                    </Stack>
                </Stack>
            </Block.Main>
        </Block>
    );
}

export default ConversationBuildingBlockComponent;
