import { useId } from "react";
import { useTranslation } from "react-i18next";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { type SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { PollVoteOption } from "@bespeak/apollo";

export type PollVoteOptionSelectorProps = {
    value?: PollVoteOption;
    onChange?: (value: PollVoteOption) => void;
};

export const PollVoteOptionSelector = ({
    value,
    onChange,
}: PollVoteOptionSelectorProps) => {
    const { t } = useTranslation("common");
    const label = t("vote-option.label", "Antwoord opties");
    const id = useId();

    const options = Object.values(PollVoteOption).map((option) => ({
        label: t(`vote-option.${option.toLowerCase()}`, option),
        value: option,
    }));

    const handleChange = (event: SelectChangeEvent<string>) =>
        isPollVoteOption(event.target.value) && onChange?.(event.target.value);

    return (
        <FormControl
            data-testid="select-poll-vote-option"
            sx={{ minWidth: 180 }}
        >
            <InputLabel>{label}</InputLabel>
            <Select value={value} label={label} onChange={handleChange}>
                {options.map((option) => (
                    <MenuItem
                        key={`${option.value}-${id}`}
                        value={option.value}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default PollVoteOptionSelector;

function isPollVoteOption(arg: any): arg is PollVoteOption {
    return Object.values(PollVoteOption).includes(arg);
}
