import { ObjectFit } from "@bespeak/apollo";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    type SelectChangeEvent,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export type ObjectFitSelectorProps = {
    value?: ObjectFit;
    onChange?: (value: ObjectFit) => void;
};

export const ObjectFitSelector = ({
    value,
    onChange,
}: ObjectFitSelectorProps) => {
    const { t } = useTranslation("common");
    const label = t("object-fit.label", "Object fit");

    const handleChange = (event: SelectChangeEvent<string>) => {
        if (onChange) onChange(event.target.value as ObjectFit);
    };

    const options = [
        {
            type: ObjectFit.Boxed,
            label: t("object-fit.boxed", "Boxed"),
        },
        {
            type: ObjectFit.Full,
            label: t("object-fit.full", "Full"),
        },
    ];

    return (
        <FormControl data-testid="select-object-fit" sx={{ minWidth: 180 }}>
            <InputLabel>{label}</InputLabel>
            <Select
                value={value || ObjectFit.Boxed}
                label={label}
                onChange={handleChange}
            >
                {options.map((option) => (
                    <MenuItem key={option.type} value={option.type}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default ObjectFitSelector;
