import { useEffect, useState } from "react";
import { getIsAuthenticated } from "@/lib/Auth/getIsAuthenticated";
import { getIsAuthenticatedRetryable } from "./get-is-authenticated-retryable";

export enum StatusEnum {
    Resolved = "resolved",
    Rejected = "rejected",
    Idle = "idle",
    Pending = "pending",
}

export namespace useGetIsAuthenticated {
    export type Status = StatusEnum;
    export type Options = {
        retry?: boolean;
    };
}

export function useGetIsAuthenticated(
    options: useGetIsAuthenticated.Options = {},
) {
    const [status, setStatus] = useState<StatusEnum>(StatusEnum.Pending);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [error, setError] = useState<unknown>();
    const isLoading = status === StatusEnum.Pending;

    useEffect(() => {
        (options.retry ? getIsAuthenticatedRetryable : getIsAuthenticated)()
            .then((isAuthenticated) => {
                setIsAuthenticated(isAuthenticated);
                setStatus(StatusEnum.Resolved);
            })
            .catch((error) => {
                setError(error);
                setStatus(StatusEnum.Rejected);
            })
            .finally(() => {
                setStatus(StatusEnum.Idle);
            });
    }, [isAuthenticated, options.retry, status]);

    return [isAuthenticated, isLoading, error] satisfies [
        boolean,
        boolean,
        unknown,
    ];
}
