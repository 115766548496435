import useParseBlocks from "./useParseBlocks";
import {
    Operator,
    ReferenceCardsFieldSelector,
    useGetReferenceCardsForAppQuery,
} from "@bespeak/apollo";
import { useProgramStore } from "@/store/preferences";

export const useGetBlocksForReferenceCard = (
    id: string,
    programId?: string,
) => {
    const { value: currentProgram } = useProgramStore();

    const { data, loading, error } = useGetReferenceCardsForAppQuery({
        variables: {
            pagination: {
                page: 0,
                pageSize: 1,
            },
            programId: programId || currentProgram,
            filters: [
                {
                    selector: ReferenceCardsFieldSelector.Id,
                    value: id,
                    operator: Operator.Equals,
                },
            ],
        },
        skip: !id,
    });

    const referenceCardBlocks =
        data?.getReferenceCardsForApp.rows.at(0)?.buildingBlocks;

    const parsedBlocks = useParseBlocks(referenceCardBlocks || []);

    return {
        blocks: parsedBlocks.blocks,
        loading,
        error,
    };
};

export default useGetBlocksForReferenceCard;
