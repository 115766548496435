import { memo } from "react";
import { ContentBuildingBlockEditorComponent } from "./blocks/ContentBuildingBlock.component";
import { MpcQuestionBuildingBlockComponent } from "./blocks/MpcQuestionBuildingBlock.component";
import { StandQuestionBuildingBlockComponent } from "./blocks/StandQuestionBuildingBlock.component";
import ConversationBuildingBlockComponent from "./blocks/ConversationBuildingBlock.component";
import MoodboardBuildingBlockComponent from "./blocks/MoodboardBuildingBlock.component";
import { SortQuestionBuildingBlockComponent } from "./blocks/SortQuestionBuildingBlock.component";
import CarouselBuildingBlockComponent from "@/components/organisms/BuildingBlockMapper/blocks/CarouselBuildingBlock.component";
import QuestionConnectBuildingBlockComponent from "./blocks/QuestionConnectBuildingBlock.component";
import QuoteBuildingBlockComponent from "./blocks/QuoteBuildingBlock.component";
import type { HotspotBuildingBlockProps } from "./blocks/HotspotBuildingBlockForEditor.component";
import InteractiveQuestionBuildingBlockComponent from "./blocks/InteractiveQuestionBuildingBlock.component";
import HotspotBuildingBlock from "./blocks/HotspotBuildingBlockForEditor.component";
import ReferenceCardBuildingBlockComponent, {
    type ReferenceCardBuildingBlockEditorComponentProps,
} from "./blocks/ReferenceCardBuildingBlock.component";
import SceneBuildingBlockComponent from "@/components/organisms/BuildingBlockMapper/blocks/SceneBuildingBlock.component";
import LinkBuildingBlockEditorComponent from "@/components/organisms/BuildingBlockMapper/blocks/LinkBuildingBlock.component";
import PollBuildingBlockEditorComponent from "@/components/organisms/BuildingBlockMapper/blocks/poll";

import {
    BuildingBlockType,
    type ContentBuildingBlock,
    type SceneBuildingBlock,
    type LinkBuildingBlock,
    type PollBuildingBlock,
    type QuestionConnectBuildingBlock,
    type ConversationBuildingBlock,
    type QuoteBuildingBlock,
    type MpcQuestionBuildingBlock,
    type StandQuestionBuildingBlock,
    type MoodboardBuildingBlock,
    type SortQuestionBuildingBlock,
    type CarouselBuildingBlock,
    type InteractiveQuestionBuildingBlock,
} from "./BuildingBlocks.types";

interface BaseBlockMapperComponentProps {
    type?: BuildingBlockType;
    elevate?: boolean;
    carousel?: boolean;
}

interface ContentBuildingBlockMapperProps
    extends BaseBlockMapperComponentProps,
        ContentBuildingBlock {
    type: typeof BuildingBlockType.CONTENT;
}

interface SceneBuildingBlockMapperProps
    extends BaseBlockMapperComponentProps,
        SceneBuildingBlock {
    type: typeof BuildingBlockType.SCENE;
}

interface LinkBuildingBlockMapperProps
    extends BaseBlockMapperComponentProps,
        LinkBuildingBlock {
    type: typeof BuildingBlockType.LINK;
}

interface PollBuildingBlockMapperProps
    extends BaseBlockMapperComponentProps,
        PollBuildingBlock {
    type: typeof BuildingBlockType.POLL;
}

interface ContentListBuildingBlockMapperProps
    extends BaseBlockMapperComponentProps,
        ContentBuildingBlock {
    type: typeof BuildingBlockType.CONTENT_LIST;
}

interface QuestionConnectBuildingBlockMapperProps
    extends BaseBlockMapperComponentProps,
        QuestionConnectBuildingBlock {
    type: typeof BuildingBlockType.QUESTION_CONNECT;
}

interface ConversationBuildingBlockMapperProps
    extends BaseBlockMapperComponentProps,
        ConversationBuildingBlock {
    type: typeof BuildingBlockType.CONVERSATION;
}

interface QuoteBuildingBlockMapperProps
    extends BaseBlockMapperComponentProps,
        QuoteBuildingBlock {
    type: typeof BuildingBlockType.QUOTE;
}

interface MpcQuestionBuildingBlockMapperProps
    extends BaseBlockMapperComponentProps,
        MpcQuestionBuildingBlock {
    type: typeof BuildingBlockType.MPC;
}

interface MpcWithImagesQuestionBuildingBlockMapperProps
    extends BaseBlockMapperComponentProps,
        MpcQuestionBuildingBlock {
    type: typeof BuildingBlockType.MPC_WITH_IMAGES;
}

interface StandQuestionBuildingBlockMapperProps
    extends BaseBlockMapperComponentProps,
        StandQuestionBuildingBlock {
    type: typeof BuildingBlockType.STAND;
}

interface MoodboardBuildingBlockMapperProps
    extends BaseBlockMapperComponentProps,
        MoodboardBuildingBlock {
    type: typeof BuildingBlockType.MOODBOARD;
}

interface SortQuestionBuildingBlockMapperProps
    extends BaseBlockMapperComponentProps,
        SortQuestionBuildingBlock {
    type: typeof BuildingBlockType.SORTQUESTION;
}

interface CarouselBuildingBlockMapperProps
    extends BaseBlockMapperComponentProps,
        CarouselBuildingBlock {
    type: typeof BuildingBlockType.CAROUSEL;
}

interface HotspotBuildingBlockMapper
    extends BaseBlockMapperComponentProps,
        HotspotBuildingBlockProps {
    type: typeof BuildingBlockType.HOTSPOT;
}

interface ReferenceCardBuildingBlockMapper
    extends BaseBlockMapperComponentProps,
        ReferenceCardBuildingBlockEditorComponentProps {
    type: typeof BuildingBlockType.REFERENCE_CARDS_CAROUSEL;
}

interface InteractiveQuestionBuildingBlockMapper
    extends BaseBlockMapperComponentProps,
        InteractiveQuestionBuildingBlock {
    type: typeof BuildingBlockType.INTERACTIVE_QUESTION;
}

interface UnknownBlockMapperProps
    extends BaseBlockMapperComponentProps,
        Record<any, any> {
    type?: Exclude<
        BuildingBlockType,
        | typeof BuildingBlockType.CONTENT
        | typeof BuildingBlockType.CONTENT_LIST
        | typeof BuildingBlockType.SCENE
        | typeof BuildingBlockType.LINK
        | typeof BuildingBlockType.POLL
        | typeof BuildingBlockType.MPC
        | typeof BuildingBlockType.MPC_WITH_IMAGES
        | typeof BuildingBlockType.STAND
        | typeof BuildingBlockType.MOODBOARD
        | typeof BuildingBlockType.QUESTION_CONNECT
        | typeof BuildingBlockType.CONVERSATION
        | typeof BuildingBlockType.QUOTE
        | typeof BuildingBlockType.SORTQUESTION
        | typeof BuildingBlockType.CAROUSEL
        | typeof BuildingBlockType.HOTSPOT
        | typeof BuildingBlockType.REFERENCE_CARDS_CAROUSEL
        | typeof BuildingBlockType.INTERACTIVE_QUESTION
    >;
}

// Combine the various types into a single discriminated union
type BlocksBuildingBlockMapperComponentProps =
    | ContentBuildingBlockMapperProps
    | ContentListBuildingBlockMapperProps
    | SceneBuildingBlockMapperProps
    | LinkBuildingBlockMapperProps
    | PollBuildingBlockMapperProps
    | MpcQuestionBuildingBlockMapperProps
    | MpcWithImagesQuestionBuildingBlockMapperProps
    | StandQuestionBuildingBlockMapperProps
    | MoodboardBuildingBlockMapperProps
    | QuestionConnectBuildingBlockMapperProps
    | ConversationBuildingBlockMapperProps
    | QuoteBuildingBlockMapperProps
    | SortQuestionBuildingBlockMapperProps
    | CarouselBuildingBlockMapperProps
    | HotspotBuildingBlockMapper
    | ReferenceCardBuildingBlockMapper
    | InteractiveQuestionBuildingBlockMapper
    | UnknownBlockMapperProps;

type BuildingBlockMapperComponentProps =
    BlocksBuildingBlockMapperComponentProps & { [key: string]: any };

function NonMemoBuildingBlockMapperComponent(
    props: BuildingBlockMapperComponentProps,
) {
    const { availableLabels, ...buildingBlock } = props;

    switch (buildingBlock.type) {
        case BuildingBlockType.CONTENT:
        case BuildingBlockType.CONTENT_LIST:
            return <ContentBuildingBlockEditorComponent {...buildingBlock} />;
        case BuildingBlockType.SCENE:
            return <SceneBuildingBlockComponent {...buildingBlock} />;
        case BuildingBlockType.LINK:
            return <LinkBuildingBlockEditorComponent {...buildingBlock} />;
        case BuildingBlockType.POLL:
            return <PollBuildingBlockEditorComponent {...buildingBlock} />;
        case BuildingBlockType.MPC:
        case BuildingBlockType.MPC_WITH_IMAGES:
            return (
                <MpcQuestionBuildingBlockComponent
                    {...buildingBlock}
                    availableLabels={availableLabels}
                />
            );
        case BuildingBlockType.STAND:
            return (
                <StandQuestionBuildingBlockComponent
                    {...buildingBlock}
                    availableLabels={availableLabels}
                />
            );
        case BuildingBlockType.MOODBOARD:
            return <MoodboardBuildingBlockComponent {...buildingBlock} />;
        case BuildingBlockType.QUESTION_CONNECT:
            return (
                <QuestionConnectBuildingBlockComponent
                    {...buildingBlock}
                    availableLabels={availableLabels}
                />
            );
        case BuildingBlockType.CONVERSATION:
            return <ConversationBuildingBlockComponent {...buildingBlock} />;
        case BuildingBlockType.QUOTE:
            return <QuoteBuildingBlockComponent {...buildingBlock} />;
        case BuildingBlockType.SORTQUESTION:
            return (
                <SortQuestionBuildingBlockComponent
                    {...buildingBlock}
                    availableLabels={availableLabels}
                />
            );
        case BuildingBlockType.CAROUSEL:
            return <CarouselBuildingBlockComponent {...buildingBlock} />;
        case BuildingBlockType.HOTSPOT:
            return <HotspotBuildingBlock {...buildingBlock} />;
        case BuildingBlockType.REFERENCE_CARDS_CAROUSEL:
            return <ReferenceCardBuildingBlockComponent {...buildingBlock} />;
        case BuildingBlockType.INTERACTIVE_QUESTION:
            return (
                <InteractiveQuestionBuildingBlockComponent {...buildingBlock} />
            );
        default:
            return (
                <pre>
                    <code>{JSON.stringify(buildingBlock)}</code>
                </pre>
            );
    }
}

/**
 * Export the memoized version of the component as to prevent useless re-renders
 * of complex block layouts.
 */
export const BuildingBlockMapperComponent = memo(
    NonMemoBuildingBlockMapperComponent,
);

export default BuildingBlockMapperComponent;
