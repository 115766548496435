import { z } from "zod";

export const backgroundSchema = z.enum([
    "BRAND_DARK",
    "BRAND_LIGHT",
    "DEFAULT",
    "NEUTRAL_DARK",
    "NEUTRAL_LIGHT",
    "PRIMARY",
    "WHITE",
    "NONE",
]);

export const baseBlockSchema = z.object({
  id: z.union([z.string().uuid(), z.number()]).optional().nullable(),
  order: z.number().optional(),
  type: z.enum(["CONTENT"]),
  label: z
    .object({
      id: z.string().uuid().optional(),
      label: z.string(),
    })
    .nullable()
    .optional()
    .default({ label: "" }),
  background: backgroundSchema
    .optional()
    .transform((value) => (value === "PRIMARY" ? "BRAND_DARK" : value)),
  textBackground: backgroundSchema.optional(),
});

export type BackgroundType = z.infer<typeof backgroundSchema>;
