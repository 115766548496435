import { type ReactNode } from "react";

const Container = ({
    children,
    className,
}: {
    children: ReactNode;
    className?: string;
}) => {
    return (
        <div className={`flex justify-center ${className}`}>
            <div className="container grid grid-cols-12 self-center px-4 sm:px-6 lg:px-8">
                {children}
            </div>
        </div>
    );
};

export default Container;
