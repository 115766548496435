import { appContentBlockSchema } from "@bespeak/schema/schemas/app-content-block-schema";
import { appListBlockSchema } from "@bespeak/schema/schemas/app-list-block.schema";
import { baseBlockSchema } from "@bespeak/schema/schemas/base-block.schema";

export const useParseBlocks = (blocks: any[]) => {
    const parsedBlocks = blocks?.map((block) => {
        switch (block.type) {
            case "CONTENT":
                if (Array.isArray(block?.list?.items)) {
                    return appListBlockSchema.parse(block); //TODO: This should be removed once the "LIST" block actually exists
                }
                return appContentBlockSchema.parse(block);
            case "LIST":
                return appListBlockSchema.parse(block);
            default:
                return baseBlockSchema.parse(block);
        }
    });

    return {
        blocks: parsedBlocks || [],
    };
};

export default useParseBlocks;
