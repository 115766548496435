import type { ReactNode } from "react";
import { SearchResultAsset } from "./SearchResultAsset.component";
import { SearchResultContent } from "./SearchResultContent.component";
import { SearchResultMain } from "./SearchResultMain.component";
import type { DidacticToolType } from "@bespeak/apollo";

export namespace SearchResult {
    export type Main = {
        linkToIds: {
            didactic_tool_id?: string;
            module_id?: string;
            educational_path_id?: string;
            reference_card_id?: string;
            educational_program_id?: string;
        };
        children?: ReactNode;
        type: string;
    };
    export type Asset = {
        type: string | DidacticToolType;
        assetId?: string;
    };
    export type Content = {
        type: string;
        title: string;
        description?: string;
        searchString?: string;
    };
}

const SearchResult = Object.assign(SearchResultMain, {
    Asset: SearchResultAsset,
    Content: SearchResultContent,
});

export default SearchResult;
