import { Asset } from "@bespeak/ui";
import { ArrowBack } from "@mui/icons-material";
import Button from "@/components/base/atoms/Button";
import Container from "@/components/base/atoms/Container/Container";
import { useTranslation } from "react-i18next";
import {
    useGetReferenceCardsForAppQuery,
    Operator,
    ReferenceCardsFieldSelector,
} from "@bespeak/apollo";
import BuildingBlockMapperForReferenceCard from "@/components/base/molecules/BuildingBlocks/BuildingBlockMapper/building-block-mapper";

export type ReferenceCardDetailProps = {
    onClick: () => void;
    value: {
        programId: string;
        id: string;
    };
};

const ReferenceCardDetail = ({
    onClick,
    value: { programId, id },
}: ReferenceCardDetailProps) => {
    const { t } = useTranslation("common", { keyPrefix: "main-menu" });
    const { data } = useGetReferenceCardsForAppQuery({
        variables: {
            programId: programId,
            pagination: {
                pageSize: 1,
                page: 0,
            },
            filters: [
                {
                    selector: ReferenceCardsFieldSelector.Id,
                    operator: Operator.Equals,
                    value: id,
                },
            ],
        },
    });

    const { headerImage, title } = data?.getReferenceCardsForApp.rows[0] || {};

    return (
        <div className="fixed left-0 top-0 z-[1001] max-h-screen min-h-screen min-w-full overflow-scroll bg-white">
            <Container className="sticky top-0 z-30 h-20 bg-white">
                <div className="col-span-12 flex items-center gap-6 py-4">
                    <Button
                        className="flex items-center py-1 pr-0 font-bold text-gray-700"
                        variant="icon"
                        onClick={onClick}
                    >
                        <ArrowBack className="mr-1 scale-90" /> {t("back")}
                    </Button>
                    <div className="h-full w-[1px] bg-gray-700" />
                    <p className="font-body font-bold text-gray-700">{title}</p>
                </div>
            </Container>
            <div className="relative w-full">
                {headerImage && (
                    <Asset.Lazy assetId={headerImage} aspectRatio={"16/4"} />
                )}
            </div>
            <BuildingBlockMapperForReferenceCard
                programId={programId}
                id={id}
            />
        </div>
    );
};

export default ReferenceCardDetail;
