import { Fragment, type ReactNode } from "react";
import type { UserDto } from "@bespeak/apollo";
import Button from "../../atoms/Button";
import { useNavigate } from "react-router-dom";

type NavigationUserMenuProps = {
    user?: Partial<UserDto>;
    menus?: {
        icon: ReactNode;
        label: string;
        href: string;
    }[][];
    onClick: () => void;
};

const NavigationUserMenu = ({
    user,
    menus,
    onClick,
}: NavigationUserMenuProps) => {
    const navigate = useNavigate();

    const handleClick = (href: string) => {
        navigate(href);
        onClick();
    };

    if (!user) return null;

    return (
        <>
            {menus?.map((menu, index) => (
                <Fragment key={`mobile-user-menu-${index}`}>
                    <div className="w-full border-t border-neutral-800"></div>
                    <div
                        key={`mobile-user-menu-${index}`}
                        className="flex flex-col gap-1"
                    >
                        {menu.map((item, subindex) => (
                            <Button
                                key={`mobile-user-menu-${index}-${subindex}`}
                                variant="neutralGhostDark"
                                size="small"
                                className="w-full justify-start"
                                onClick={() => {
                                    handleClick(item.href);
                                }}
                            >
                                {item.icon}
                                <span>{item.label}</span>
                            </Button>
                        ))}
                    </div>
                </Fragment>
            ))}
        </>
    );
};

export default NavigationUserMenu;
