import {
    type GetUserConfigurationQuery,
    useGetUserConfigurationSuspenseQuery,
} from "@bespeak/apollo";
import { skipToken } from "@apollo/client";
import { createContext, type ReactNode } from "react";
import { useAuth } from "react-oidc-context";

export const userConfigContext = createContext<
    NonNullable<GetUserConfigurationQuery>["userConfiguration"] | undefined
>(undefined);

export function UserConfigProvider({ children }: { children: ReactNode }) {
    const { isAuthenticated } = useAuth();
    const { data } = useGetUserConfigurationSuspenseQuery(
        !isAuthenticated ? skipToken : {},
    );

    const userConfig = data?.userConfiguration;

    return (
        <userConfigContext.Provider value={userConfig}>
            {children}
        </userConfigContext.Provider>
    );
}

export default UserConfigProvider;
