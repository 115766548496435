import { useTranslation } from "react-i18next";
import type { ValidationError } from "yup";
import Stack from "@mui/material/Stack";
import Input from "@/components/atoms/Input";

import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";

export namespace AnswerControl {
    export interface Props {
        value: string;
        canMoveUp?: boolean;
        canMoveDown?: boolean;
        onChange: (value: string) => void;
        onDelete?: () => void;
        onMoveUp?: () => void;
        onMoveDown?: () => void;
        errors?: ValidationError[];
    }
}

export function AnswerControl({
    value,
    canMoveUp,
    canMoveDown,
    onChange,
    onDelete,
    onMoveUp,
    onMoveDown,
    errors,
}: AnswerControl.Props) {
    const { t } = useTranslation("translation", {
        keyPrefix: "organisms.buildingBlockMapper.blocks.poll",
    });

    const textError = errors?.find((error) => error.path?.endsWith(".text"));

    return (
        <ListItem
            secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={onDelete}>
                    <DeleteIcon />
                </IconButton>
            }
        >
            <ListItemAvatar>
                <Avatar>
                    <QuestionMarkIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Input
                            value={value}
                            onChange={onChange}
                            placeholder={t("answer.placeholder")}
                            variant="body1"
                            error={textError}
                        />
                        <IconButton
                            edge="end"
                            aria-label="move-up"
                            onClick={onMoveUp}
                            disabled={!canMoveUp}
                        >
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton
                            edge="end"
                            aria-label="move-down"
                            onClick={onMoveDown}
                            disabled={!canMoveDown}
                        >
                            <ArrowDownwardIcon />
                        </IconButton>
                        <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ ml: 1 }}
                        />
                    </Stack>
                }
            />
        </ListItem>
    );
}

export default AnswerControl;
