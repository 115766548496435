import { cva } from "cva";
import { State } from "./ProgressBar.State";
import css from "./Dot.module.css";

export const dot = cva({
    base: css.Dot,
    variants: {
        state: {
            [State.CORRECT]: [css.correct, "bg-success"],
            [State.INCORRECT]: [css.incorrect, "bg-error"],
            [State.UNANSWERED]: [css.unanswered, "bg-neutral-200"],
            [State.CURRENT]: [css.current, "bg-primary"],
        },
    },
    defaultVariants: {
        state: State.UNANSWERED,
    },
});
