import getInitials from "@/lib/getInitials/getInitials";
import { Asset } from "@bespeak/ui";
import { useDetailedAsset } from "@bespeak/ui/src/hooks";

interface AvatarProps {
    asset?: string | null;
    name: string;
    className?: string;
    size: "sm" | "lg" | "xl" | "xxl";
    border: "primary" | "neutral" | "none";
    isPrimaryBackground: boolean;
}

const Avatar = ({
    asset,
    name,
    className,
    size,
    border,
    isPrimaryBackground,
}: AvatarProps) => {
    const initials = getInitials(name);
    const detailedAsset = useDetailedAsset(asset || "");

    const width = {
        sm: "min-w-10 max-w-10 text-base",
        lg: "min-w-[72px] max-w-[72px] border-4 text-xl",
        xl: "min-w-24 max-w-24 border-6 text-4xl",
        xxl: "min-w-40 max-w-40 border-6 text-4xl",
    };

    const borderColor = {
        primary: isPrimaryBackground ? "border-neutral-900" : "border-primary",
        neutral: "border-neutral-300",
        none: "border-none",
    };

    return (
        <div
            className={`${className} ${width[size]} ${borderColor[border]} flex aspect-square items-center justify-center overflow-hidden rounded-full ${isPrimaryBackground ? "bg-neutral-900" : "bg-primary"}`}
        >
            {detailedAsset ? (
                <Asset asset={detailedAsset} />
            ) : (
                <p className="p-4 font-medium text-white">{initials}</p>
            )}
        </div>
    );
};

export default Avatar;
