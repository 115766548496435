import type { UniqueIdentifier } from "@dnd-kit/core";
import type { ValidationError } from "yup";
import type {
    AssetDto,
    CarouselBlockLayout,
    ContentBlockLayout,
    ContentBlockListLayout,
    ConversationBlockLayout,
    HotspotBlockLayout,
    MoodboardBlockLayout,
    MpcBlockLayout,
    QuestionConnectBlockLayout,
    QuoteBlockLayout,
    SortQuestionBlockLayout,
    StandBlockLayout,
    BuildingBlocksLabel as GraphQLBuildingBlocksLabel,
    InteractiveQuestionBlockLayout,
    Sorting,
    ReferenceCardDto,
    PollVoteOption,
    PollBlockLayout,
    ObjectFit,
} from "../../../../../packages/apollo";
import type {
    MpcBuildingBlockInput,
    StandBuildingBlockInput,
} from "../../../../../packages/apollo";
import type { Key } from "react";

export const BuildingBlockType = {
    POLL: "POLL",
    CONTENT: "CONTENT",
    CONTENT_LIST: "CONTENT_LIST",
    MPC: "MPC",
    MPC_WITH_IMAGES: "MPC_WITH_IMAGES",
    STAND: "STAND",
    MOODBOARD: "MOODBOARD",
    QUESTION_CONNECT: "QUESTION_CONNECT",
    CONVERSATION: "CONVERSATION",
    QUOTE: "QUOTE",
    SORTQUESTION: "SORT_QUESTION",
    CAROUSEL: "CAROUSEL",
    HOTSPOT: "HOTSPOT",
    REFERENCE_CARDS_CAROUSEL: "REFERENCE_CARDS_CAROUSEL",
    SCENE: "SCENE",
    LINK: "LINK",
    INTERACTIVE_QUESTION: "INTERACTIVE_QUESTION",
    POLL: "POLL",
} as const;

export type BuildingBlockType =
    (typeof BuildingBlockType)[keyof typeof BuildingBlockType];

export interface BaseBuildingBlock {
    id: UniqueIdentifier;
    sortOrder?: number;
    questionNumber?: number;
    errors?: ValidationError[];
}

export type InteractiveQuestionInput =
    | MpcBuildingBlockInput
    | StandBuildingBlockInput;

export interface ConversationMessageType {
    id: UniqueIdentifier;
    message: string;
    order?: number;
    personIndex: string;
}

export interface ConversationPersonType {
    assetId?: string;
    id: UniqueIdentifier;
    name: string;
    order?: number;
}

export interface ReferenceCardType {
    order: any;
    id: UniqueIdentifier;
    sortOrder?: number;
}

export type ImageType = {
    id?: number | string;
    src?: string;
    saved?: number | string;
};

export type MpcAnswerType = {
    id?: UniqueIdentifier;
    label?: string;
    isCorrect?: boolean;
    image?: ImageType;
    content?: string;
    sortOrder?: number;
};

export type ListItemType = {
    id?: UniqueIdentifier;
    title?: string;
    subtitle?: string;
    assetId?: string;
    content?: string;
    sortOrder?: number;
};

export interface ContentBuildingBlock extends BaseBuildingBlock {
    type:
        | typeof BuildingBlockType.CONTENT
        | typeof BuildingBlockType.CONTENT_LIST;
    asset?: AssetDto;
    imageLeft?: boolean;
    background?: string;
    layout: ContentBlockLayout;
    contentColumnOne: string;
    contentColumnTwo?: string;
    objectFit: PollVoteOption;
    list?: {
        layout: ContentBlockListLayout;
        items: ListItemType[];
    };
}

export interface SceneBuildingBlock extends BaseBuildingBlock {
    type: typeof BuildingBlockType.SCENE;
    title?: string;
    description?: string;
    asset?: AssetDto;
}

export interface LinkBuildingBlock extends BaseBuildingBlock {
    type: typeof BuildingBlockType.LINK;
    title?: string;
    instruction?: string;
    url: string;
    linkLabel?: string;
    openInNewWindow: boolean;
    asset?: AssetDto;
    background?: string;
}

export interface PollBuildingBlock extends BaseBuildingBlock {
    type: typeof BuildingBlockType.POLL;
    order: number;
    instruction: string;
    question: string;
    voteOption: PollVoteOption;
    introduction: string;
    answers: { id: string; text: string; sortOrder: number }[];
}

export interface MpcQuestionBuildingBlock extends BaseBuildingBlock {
    type:
        | typeof BuildingBlockType.MPC
        | typeof BuildingBlockType.MPC_WITH_IMAGES;
    introduction?: string;
    instructionDivider?: boolean;
    question?: string;
    instruction?: string;
    answers?: MpcAnswerType[];
    objectFit: ObjectFit;
    buttonLabel?: string;
    image?: ImageType;
    background?: string;
    layout?: MpcBlockLayout;
    helpText: string;
    correctFeedbackText?: string;
    incorrectFeedbackText?: string;
    label: BuildingBlocksLabel;
    sorting: Sorting;
}

export type QuestionConnectAnswerType = {
    id?: UniqueIdentifier;
    correctAnswer?: {
        id?: UniqueIdentifier;
        content?: string;
    };
    label?: string;
    image?: { id?: string; src?: string };
};

export interface QuestionConnectBuildingBlock extends BaseBuildingBlock {
    type: typeof BuildingBlockType.QUESTION_CONNECT;
    layout?: QuestionConnectBlockLayout;
    background?: string;
    introduction?: string;
    question: string;
    instruction?: string;
    answers: QuestionConnectAnswerType[];
    correctFeedbackText?: string;
    incorrectFeedbackText?: string;
    label: BuildingBlocksLabel;
}

export interface StandQuestionBuildingBlock extends BaseBuildingBlock {
    type: typeof BuildingBlockType.STAND;
    agreeText?: string;
    image?: { id?: number | string; src?: string };
    disagreeText?: string;
    stand?: string;
    instruction?: string;
    standText?: string;
    buttonLabel?: string;
    correctOption?: "AGREE" | "DISAGREE";
    background?: string;
    layout?: StandBlockLayout;
    correctFeedbackText?: string;
    incorrectFeedbackText?: string;
    label: BuildingBlocksLabel;
}

export interface MoodboardImageType {
    assetId?: string;
    title?: string;
    text?: string;
    sortOrder: number;
}

export interface MoodboardBuildingBlock extends BaseBuildingBlock {
    type: typeof BuildingBlockType.MOODBOARD;
    moodboardLayout: MoodboardBlockLayout;
    background?: string;
    images: MoodboardImageType[];
}

export interface ConversationBuildingBlock extends BaseBuildingBlock {
    type: typeof BuildingBlockType.CONVERSATION;
    interval: number;
    label: string;
    messages: ConversationMessageType[];
    persons: ConversationPersonType[];
    quote: string;
    quotePersonIndex: number;
    background?: string;
    layout?: ConversationBlockLayout;
}

export interface HotspotBuildingBlock extends BaseBuildingBlock {
    background: string;
    type: typeof BuildingBlockType.HOTSPOT;
    assetId?: string;
    spots: {
        id: Key;
        x: number;
        y: number;
        title: string;
        content: string;
    }[];
    layout?: HotspotBlockLayout;
    title: string;
    content: string;
}

export interface ReferenceCardBuildingBlock extends BaseBuildingBlock {
    background: string;
    layout: string;
    title: string;
    type: typeof BuildingBlockType.REFERENCE_CARDS_CAROUSEL;
    cards: ReferenceCardDto[];
}

export interface CarouselBuildingBlock extends BaseBuildingBlock {
    type: typeof BuildingBlockType.CAROUSEL;
    title: string;
    blocks: BuildingBlock[];
    background?: string;
    layout?: CarouselBlockLayout;
}

export interface QuoteBuildingBlock extends BaseBuildingBlock {
    type: typeof BuildingBlockType.QUOTE;
    layout?: QuoteBlockLayout;
    quoteLayout?: QuoteBlockLayout;
    quote: string;
    name: string;
    assetId: string;
    background: string;
}

export interface SortQuestionAnswers {
    id: number;
    label: string;
    order: number;
}

export interface SortQuestionImage {
    id?: number | string;
    src?: string;
}

export interface SortQuestionBuildingBlock extends BaseBuildingBlock {
    type: typeof BuildingBlockType.SORTQUESTION;
    layout?: SortQuestionBlockLayout;
    introduction?: string;
    question: string;
    instruction?: string;
    objectFit: ObjectFit;
    answers: SortQuestionAnswers[];
    correctFeedbackText?: string;
    incorrectFeedbackText?: string;
    image?: SortQuestionImage;
    background?: string;
    label: BuildingBlocksLabel;
}

export interface InteractiveQuestionBuildingBlock extends BaseBuildingBlock {
    type: typeof BuildingBlockType.INTERACTIVE_QUESTION;
    layout?: InteractiveQuestionBlockLayout;
    label?: string;
    question?: InteractiveQuestionInput;
    sceneWhenWrong: SceneBuildingBlock;
    background: string;
    order: number;
}

export interface PollQuestionAnswer {
    id: number | string;
    text: string;
    sortOrder: number;
}

export interface PollQuestionBuildingBlock extends BaseBuildingBlock {
    type: typeof BuildingBlockType.POLL;
    layout?: PollBlockLayout;
    introduction?: string;
    question: string;
    instruction?: string;
    answers: PollQuestionAnswer[];
    background?: string;
    label: BuildingBlocksLabel;
}

export type BuildingBlock =
    | ContentBuildingBlock
    | MpcQuestionBuildingBlock
    | StandQuestionBuildingBlock
    | MoodboardBuildingBlock
    | SortQuestionBuildingBlock
    | QuoteBuildingBlock
    | QuestionConnectBuildingBlock
    | ConversationBuildingBlock
    | CarouselBuildingBlock
    | HotspotBuildingBlock
    | ReferenceCardBuildingBlock
    | SceneBuildingBlock
    | LinkBuildingBlock
    | PollQuestionBuildingBlock
    | InteractiveQuestionBuildingBlock;

export type BuildingBlocksLabel = GraphQLBuildingBlocksLabel;
