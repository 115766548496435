import { noop, useDialogStack } from "@bespeak/ui";
import ReferenceCardDetail, {
    type ReferenceCardDetailProps,
} from "./ReferenceCardDetail";

export namespace useReferenceCardDialog {
    export type Value = ReferenceCardDetailProps;

    export interface Props {
        value: {
            programId: string;
            id: string;
        };
        onDismiss?: () => void;
        onClose?: (newValue: Value) => void;
    }

    export interface ReturnValue {
        open: () => void;
        close: () => void;
    }
}

export function useReferenceCardDialog({
    value,
    onDismiss = noop,
}: useReferenceCardDialog.Props): useReferenceCardDialog.ReturnValue {
    const dialogStack = useDialogStack();

    function close() {
        dialogStack.pop();
    }

    function handleOnDismiss() {
        onDismiss();
        close();
        document.body.style.overflow = "scroll";
    }

    function open() {
        dialogStack.push({
            component: (
                <ReferenceCardDetail
                    value={{
                        programId: value.programId,
                        id: value.id,
                    }}
                    onClick={handleOnDismiss}
                />
            ),
        });
    }

    return {
        open,
        close,
    };
}
