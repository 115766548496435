import { type ReactNode } from "react";
import { Outlet } from "react-router-dom";
import { HelpBubble } from "@bespeak/ui";
import { useParams } from "react-router-dom";

import Search from "../Search/Search.component";

export default function MainLayout({ children }: { children?: ReactNode }) {
    const didacticToolId = useParams<{ didacticToolId?: string }>()
        .didacticToolId;

    return (
        <>
            <Search />
            {children || <Outlet />}
            {didacticToolId === undefined ? <HelpBubble /> : null}
        </>
    );
}
