import { cva } from "cva";

export const checkboxStyles = cva({
    base: "appearance-none border block overflow-hidden",
    compoundVariants: [
        {
            disabled: true,
            inderterminate: false,
            className: "cursor-not-allowed",
        },
        {
            disabled: false,
            inderterminate: true,
            className: "border-neutral-200 bg-neutral-200 outline-none ring-0",
        },
        {
            disabled: false,
            inderterminate: false,
            interactive: true,
            className:
                "hover:border-neutral-800 focus:outline focus:outline-offset-2 focus:outline-neutral-800",
        },
        {
            disabled: false,
            inderterminate: false,
            interactive: true,
            className: "hover:outline-neutral-800",
        },
    ],
    variants: {
        size: {
            large: "h-6 w-6 rounded-lg",
            default: "w-5 h-5 rounded-md",
        },
        disabled: {
            true: "border-neutral-300",
            false: "",
        },
        checked: {
            //Leave empty for type safety in CVA, handled by compound variants
            true: "",
            false: "",
        },
        inderterminate: {
            true: "cursor-auto",
            false: "border-neutral-200 bg-white  ",
        },
        interactive: {
            true: "",
            false: "cursor-default ring-0 outline-none",
        },
    },

    defaultVariants: {
        size: "default",
        checked: false,
        inderterminate: false,
        disabled: false,
    },
});
