import { cva } from "cva";

const radioStyles = cva({
    base: "transition-100 appearance-none  rounded-full outline-1 outline outline-neutral-300 transition-[border-width,border,outline-color] ",
    compoundVariants: [
        {
            disabled: true,
            checked: true,
            inderterminate: false,
            className:
                "bg-neutral-500 border-white border-[3px] outline-neutral-300",
        },
        {
            disabled: true,
            checked: false,
            inderterminate: false,
            className: "bg-neutral-200 border-neutral-200 outline-neutral-200",
        },
        {
            disabled: false,
            checked: true,
            inderterminate: false,
            className: "border-[3px] border-white bg-neutral-800",
        },
        {
            disabled: false,
            checked: true,
            inderterminate: true,
            className: "border-[3px] border-white bg-neutral-800",
        },
        {
            interactive: true,
            disabled: false,
            checked: false,
            className: "hover:outline-neutral-800",
        },
    ],
    variants: {
        size: {
            large: "h-6 w-6",
            default: "w-5 h-5",
        },
        disabled: {
            true: " outline-neutral-200 cursor-not-allowed",
            false: "",
        },
        checked: {
            //Leave empty for type safety in CVA, handled by compound variants
            true: "",
            false: "",
        },
        inderterminate: {
            true: "",
            false: "",
        },
        interactive: {
            true: "cursor-pointer",
            false: "cursor-default",
        },
    },

    defaultVariants: {
        size: "default",
        checked: false,
        inderterminate: false,
        disabled: false,
    },
});

export default radioStyles;
