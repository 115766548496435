import useGetBlocksForReferenceCard from "./useGetBlocksForReferenceCard";
import { Suspense } from "react";
import { type AppBlock, RenderComponent } from "./map-blocks";
import Skeleton from "react-loading-skeleton";

export type BuildingBlockMapperProps = {
    className?: string;
    programId: string;
    id: string;
};

export const BuildingBlockMapperForReferenceCard = ({
    className,
    programId,
    id,
}: BuildingBlockMapperProps) => {
    const { blocks } = useGetBlocksForReferenceCard(id, programId);

    return (
        <main className={className}>
            {blocks?.map((block) => {
                // Find the corresponding lazy-loaded component
                const Component = RenderComponent(block as AppBlock);

                if (!Component || !block) {
                    return null;
                }
                return (
                    <Suspense
                        fallback={
                            <Skeleton
                                height="100px"
                                baseColor="var(--secondary)"
                                highlightColor="var(--primary-200)"
                                enableAnimation={true}
                            />
                        }
                        key={block.id}
                    >
                        <Component {...block} />
                    </Suspense>
                );
            })}
        </main>
    );
};

export default BuildingBlockMapperForReferenceCard;
