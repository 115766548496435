import { z } from "zod";
import { baseContentBlockSchema } from "./base-content-block-schema";
import { cmsContentBlockLayoutSchema } from "./cms-content-block.schema";

const isValidJson = (jsonString: string) => {
    try {
        JSON.parse(jsonString);
        return true;
    } catch (e) {
        return false;
    }
};

export const quillHasContent = (jsonString: string) => {
    if (!isValidJson(jsonString)) return false;

    const parsedJson = JSON.parse(jsonString);

    if (!parsedJson?.ops) {
        return false;
    }

    return parsedJson?.ops.some((op: any) => {
        if (op?.insert) {
            return op.insert.trim() !== "";
        }
    });
};

export const appContentBlockSchema = baseContentBlockSchema
    .merge(
        z.object({
            id: z.string().uuid(),
            label: z
                .object({
                    id: z.string().uuid(),
                    label: z.string(),
                })
                .optional(),
            textLayout: cmsContentBlockLayoutSchema.optional(),
            layout: cmsContentBlockLayoutSchema.optional(),
        }),
    )
    .transform((data) => {
        return {
            ...data,
            contentColumnOne: quillHasContent(data.contentColumnOne || "{}")
                ? data.contentColumnOne
                : null,
            contentColumnTwo: quillHasContent(data.contentColumnTwo || "{}")
                ? data.contentColumnTwo
                : null,
        };
    });
