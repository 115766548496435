import Block from "@/components/atoms/Block";
import LayoutSelector from "@/components/molecules/LayoutSelector";
import SelectBgColor from "./common/SelectBgColor";
import { useState } from "react";
import css from "./SortQuestionBuildingBlock.module.scss";
import { Stack } from "@mui/material";
import { ObjectFit, SortQuestionBlockLayout } from "@bespeak/apollo";
import WysiwygEditor from "@/components/atoms/WysiwygEditor";
import { useTranslation } from "react-i18next";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";
import { SortQuestionAnswers } from "@/components/molecules/SortQuestionAnswers/SortQuestionAnswers";
import Media from "@/components/atoms/Media";
import QuestionFields from "./common/Question/Question";
import {
    BuildingBlockType,
    type BuildingBlocksLabel,
    type SortQuestionBuildingBlock,
} from "@/components/organisms/BuildingBlockMapper";
import SelectLabel from "@/components/organisms/BuildingBlockMapper/blocks/common/SelectLabel";
import ObjectFitSelector from "@/components/molecules/ObjectFitSelector/ObjectFitSelector.component";

export interface SortQuestionBuildingBlockComponentProps
    extends SortQuestionBuildingBlock {
    availableLabels: BuildingBlocksLabel[];
    onChange?: (buildingBlock: SortQuestionBuildingBlock) => void;
}

export function SortQuestionBuildingBlockComponent(
    props: SortQuestionBuildingBlockComponentProps,
) {
    type Fields = Pick<
        SortQuestionBuildingBlock,
        | "introduction"
        | "instruction"
        | "question"
        | "correctFeedbackText"
        | "incorrectFeedbackText"
        | "background"
    >;

    const [label, setLabel] = useState(props.label || null);

    const { t } = useTranslation("common");
    const [bgColor, setBgColor] = useState(props.background || "DEFAULT");
    const layout = props.layout || SortQuestionBlockLayout.TwoColumns;

    const modulesForFeedback = {
        toolbar: [["bold", "italic", "underline", "strike"], [{ header: [6] }]],
    };

    const handleChange =
        (key: keyof Fields) => (value: Fields[keyof Fields]) => {
            props.onChange?.({
                ...props,
                [key]: value,
            });
        };

    const registerControl = (key: keyof Fields) => ({
        value: props[key],
        onChange: handleChange(key),
    });

    const layoutOptions = [
        { type: SortQuestionBlockLayout.TwoColumns },
        { type: SortQuestionBlockLayout.ImageLeft },
        { type: SortQuestionBlockLayout.ImageRight },
        { type: SortQuestionBlockLayout.TextCentered },
    ];

    const questionError = resolveError({
        errors: props.errors,
        path: "question",
    });

    const question = (
        <QuestionFields
            questionValue={props.question}
            introductionValue={props.introduction}
            onChange={(value, key) => {
                props.onChange?.({
                    ...props,
                    [key]: value,
                });
            }}
            error={questionError}
            instructionValue={props.instruction}
        />
    );

    const correctFeedbackText = (
        <div>
            <WysiwygEditor
                {...registerControl("correctFeedbackText")}
                modules={modulesForFeedback}
                label={t(
                    "correct-feedback-text-label",
                    "Feedback bij correct antwoord",
                )}
                placeholder={t(
                    "correct-feedback-text-placeholder",
                    "Feedback bij correct antwoord",
                )}
            />
        </div>
    );

    const incorrectFeedbackText = (
        <div>
            <WysiwygEditor
                {...registerControl("incorrectFeedbackText")}
                modules={modulesForFeedback}
                label={t(
                    "incorrect-feedback-text-label",
                    "Feedback bij incorrect antwoord",
                )}
                placeholder={t(
                    "incorrect-feedback-text-placeholder",
                    "Feedback bij incorrect antwoord",
                )}
            />
        </div>
    );

    const isLayoutWithImage = [
        SortQuestionBlockLayout.ImageLeft,
        SortQuestionBlockLayout.ImageRight,
    ].includes(layout);

    const answers = <SortQuestionAnswers {...props} />;

    const imageBlock = (
        <Media
            image={props.image}
            onChange={(image) => {
                props.onChange?.({
                    ...props,
                    ...(image != null && {
                        image: { id: image },
                    }),
                    ...(image === null && {
                        image: undefined,
                    }),
                });
            }}
            error={resolveError({ errors: props.errors, path: "image.id" })}
        />
    );

    return (
        <Block>
            <Block.Header>
                <Stack gap={1} direction="row" alignItems={"center"}>
                    <LayoutSelector
                        type={BuildingBlockType.SORTQUESTION}
                        value={layout}
                        layoutOptions={layoutOptions}
                        onChange={(e) => {
                            props.onChange?.({
                                ...props,
                                layout: e as SortQuestionBlockLayout,
                            });
                        }}
                    />
                    {isLayoutWithImage && (
                        <ObjectFitSelector
                            onChange={(value) => {
                                props.onChange?.({
                                    ...props,
                                    objectFit: value,
                                });
                            }}
                            value={props.objectFit || ObjectFit.Boxed}
                        />
                    )}

                    <SelectBgColor
                        {...registerControl("background")}
                        onChange={(event) => {
                            setBgColor(event);
                            props.onChange?.({
                                ...props,
                                background: event,
                            });
                        }}
                    />
                    <SelectLabel
                        options={props.availableLabels}
                        onChange={(newValue) => {
                            setLabel(newValue);
                            props.onChange?.({
                                ...props,
                                label: newValue,
                            });
                        }}
                        value={label}
                    />
                </Stack>
            </Block.Header>
            <Block.Main className={bgColor === "PRIMARY" ? css.primary : ""}>
                <Stack gap={4} direction="row">
                    {layout === SortQuestionBlockLayout.TwoColumns ? (
                        <>
                            <Stack gap={4} flexBasis="50%">
                                {question}
                                {correctFeedbackText}
                                {incorrectFeedbackText}
                            </Stack>
                            <Stack gap={4} flexBasis="50%">
                                {answers}
                            </Stack>
                        </>
                    ) : layout === SortQuestionBlockLayout.TextCentered ? (
                        <>
                            <Stack gap={4} flexBasis="100%">
                                {question}
                                {correctFeedbackText}
                                {incorrectFeedbackText}
                                {answers}
                            </Stack>
                        </>
                    ) : layout === SortQuestionBlockLayout.ImageLeft ? (
                        <>
                            <Stack gap={1} flexBasis="50%">
                                {imageBlock}
                            </Stack>
                            <Stack gap={4} flexBasis="50%">
                                {question}
                                {answers}
                                {correctFeedbackText}
                                {incorrectFeedbackText}
                            </Stack>
                        </>
                    ) : layout === SortQuestionBlockLayout.ImageRight ? (
                        <>
                            <Stack gap={4} flexBasis="50%">
                                {question}
                                {answers}
                                {correctFeedbackText}
                                {incorrectFeedbackText}
                            </Stack>
                            <Stack gap={4} flexBasis="50%">
                                {imageBlock}
                            </Stack>
                        </>
                    ) : null}
                </Stack>
            </Block.Main>
        </Block>
    );
}

export default SortQuestionBuildingBlockComponent;
