import { z } from "zod";
import { baseListBlockSchema } from "./base-list-block.schema";
import { appContentListItemSchema } from "./app-content-list-item.schema";

export const appListBlockSchema = baseListBlockSchema.and(
    z.object({
        id: z.string().uuid(),
        label: z
            .object({
                id: z.string().uuid(),
                label: z.string(),
            })
            .optional(),
        list: z.object({
            items: z.array(appContentListItemSchema),
        }),
    }),
);
